import Footer from "./Footer";
import Navbar from "./Navbar";

const MainContainer = (props) => {
    return (
        <div className="body-container">
            <Navbar />
            <div className="main-container bgc-white">
                {props.children}
            </div>
            <Footer />
        </div>
    )
};

export default MainContainer;